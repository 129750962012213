import styled from "styled-components";
import { textSelection, color as themeColor } from "@styles";
import Link from "next/link";

export const Anchor = styled(Link)<{ color?: string; $isUnderlined?: boolean }>`
  display: inline;
  font-weight: normal;
  text-decoration: underline;
  color: ${(p) => p.color || "#5C5757"};

  &:hover {
    color: ${themeColor.P100};
    text-decoration: ${(p) => (p.$isUnderlined ? "underline" : "")};
  }

  ${textSelection}

  @media only screen and (max-width: 65rem) {
    margin-top: 0.25rem;
  }
`;
