import styled from "styled-components";
import { fonts, color as themeColor } from "@styles";

export interface ParagraphProps {
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  color?: string;
  align?: "left" | "center" | "right" | "inherit";
  bold?: boolean;
  underlined?: boolean;
  fontSize?: string;
  lineHeight?: string;
  margin?: string;
}

const P = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;

  ::-moz-selection {
    background: ${themeColor.P20};
  }
  ::selection {
    background: ${themeColor.P20};
  }

  text-align: inherit;
  padding-top: 1.5rem;
  color: ${themeColor.B1100};
  text-decoration: inherit;
`;

export const P1 = styled(P)<ParagraphProps>`
  ${(p) => (p.align ? `text-align: ${p.align};` : "")}
  ${(p) => (p.pt ? `padding-top: ${p.pt};` : "")}
  ${(p) => (p.pb ? `padding-bottom: ${p.pb};` : "")}
  ${(p) => (p.pl ? `padding-left: ${p.pl};` : "")}
  ${(p) => (p.pr ? `padding-right: ${p.pr};` : "")}
  ${(p) => (p.color ? `color: ${p.color};` : "")}
  ${(p) => (p.bold ? "  font-weight: bold;" : "")}
  ${(p) => (p.underlined ? "text-decoration: underline;" : "")}
  ${(p) => (p.fontSize ? `font-size: ${p.fontSize};` : "")}
  ${(p) => (p.lineHeight ? `line-height: ${p.lineHeight};` : "")}
  ${(p) => (p.margin ? `margin: ${p.margin};` : "")}
`;

export const P2 = styled(P1)<ParagraphProps>`
  font-size: 1rem;
  line-height: 1.6rem;
  ${(p) => (p.align ? `text-align: ${p.align};` : "")}
  ${(p) => (p.pt ? `padding-top: ${p.pt};` : "")}
  ${(p) => (p.pb ? `padding-bottom: ${p.pb};` : "")}
  ${(p) => (p.pl ? `padding-left: ${p.pl};` : "")}
  ${(p) => (p.pr ? `padding-right: ${p.pr};` : "")}
  ${(p) => (p.color ? `color: ${p.color};` : "")}
  ${(p) => (p.bold ? "  font-weight: bold;" : "")}
  ${(p) => (p.underlined ? "text-decoration: underline;" : "")}
  ${(p) => (p.lineHeight ? `font-size: ${p.lineHeight};` : "")}
  ${(p) => (p.fontSize ? `line-height: ${p.fontSize};` : "")}
  ${(p) => (p.margin ? `margin: ${p.margin};` : "")}
`;

export const Speech = styled.p<ParagraphProps & { textAlign?: string }>`
  font-family: ${fonts.speech};
  font-size: 2rem;
  line-height: 1;
  font-style: normal;
  font-weight: normal;
  color: #e30d76;
  ${(p) => (p.textAlign ? `text-align: ${p.textAlign}` : "")};
`;
