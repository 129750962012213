import styled from "styled-components";
import { mobile, tablet } from "./Breakpoints";

interface SpacerProps {
  height?: string | string[];
  width?: string;
  margin?: string | string[];
  background?: string;
}

export const Spacer = styled.div<SpacerProps>`
  margin: 0;
  padding: 0;
  width: ${(p) => (p.width ? p.width : "100%")};

  ${(p) =>
    p.height ? `height: ${typeof p.height === "string" ? p.height : p.height[0] || "0"}` : null};
  ${(p) =>
    p.margin
      ? `margin-top: ${typeof p.margin === "string" ? p.margin : p.margin[0] || "0"}`
      : null};

  ${tablet} {
    ${(p) => (p.height && p.height.length ? `height: ${p.height[1] || p.height[0]};` : null)};
    ${(p) => (p.margin && p.margin.length ? `margin-top: ${p.margin[1] || p.margin[0]};` : null)};
  }

  ${mobile} {
    ${(p) =>
      p.height && p.height.length ? `height: ${p.height[2] || p.height[1] || p.height[0]};` : null};
    ${(p) =>
      p.margin && p.margin.length
        ? `margin-top: ${p.margin[2] || p.margin[1] || p.margin[0]};`
        : null};
  }

  ${(p) => (p.background ? `background: ${p.background}` : null)};
`;

export const DesktopSpacer = styled.div<{ height: string; width?: string; background?: string }>`
  margin: 0;
  padding: 0;
  width: ${(p) => (p.width ? p.width : "100%")};
  ${(p) => (p.height ? `height: ${p.height}` : null)};
  ${(p) => (p.background ? `background: ${p.background}` : null)};

  @media only screen and (max-width: 52rem) {
    height: 0;
  }
`;
