import styled from "styled-components";

export const StandardPadding = styled.div`
  padding: 0 2rem;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 30rem) {
    padding: 0 5%;
  }
`;
