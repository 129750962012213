import styled from "styled-components";

export const mobile = "@media only screen and (max-width: 45rem)";
export const tablet = "@media only screen and (max-width: 65rem)";
export const desktop = "@media only screen and (min-width: 65rem)";
export const shrinkNav = "@media only screen and (max-width: 83rem)";

export const MobileOnly = styled.div`
  display: none;

  ${mobile} {
    display: block;
  }
`;

export const TabletOnly = styled.div`
  display: none;

  ${tablet} {
    display: block;
  }

  ${mobile} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: block;

  ${tablet} {
    display: none;
  }
`;

export const NotMobile = styled.div`
  display: block;

  ${mobile} {
    display: none;
  }
`;

export const NotTablet = styled.div`
  display: block;

  ${tablet} {
    display: none;
  }

  ${mobile} {
    display: block;
  }
`;

export const NotDesktop = styled.div`
  display: none;

  ${tablet} {
    display: block;
  }
`;
