import { color } from "@styles";
import { FC, ReactElement } from "react";
import styled from "styled-components";
import { convertNewlinesToBreaks } from "utils/string";
import { Tooltip } from "./Tooltip";

interface SingleStatProps {
  stat: Stat;
  inverted?: boolean;
  extended?: boolean;
  customBreakpoint?: string;
}

interface Stat {
  value: string;
  description: string;
  tooltip?: string | ReactElement;
  tooltipDesktopWidth?: string;
  marginLeft?: string;
  marginRight?: string;
  bold?: boolean;
  maxWidth?: string;
}

export const SingleStat: FC<SingleStatProps> = ({
  stat: {
    value,
    description,
    tooltip,
    tooltipDesktopWidth,
    marginLeft,
    marginRight,
    bold = false,
    maxWidth,
  },
  inverted = false,
  extended = false,
  customBreakpoint = "65rem"
}) => {
  const isNotBold = (inverted && !bold) || extended


  return (
    <StatContainer extended={extended} customBreakpoint={customBreakpoint}>
      <Stat inverted={inverted}>{value}</Stat>
      <Description
        marginLeft={marginLeft}
        marginRight={marginRight}
        inverted={inverted}
        hasTooltip={!!tooltip}
        weight={isNotBold}
        maxWidth={maxWidth}
        extended={extended}
      >
        <span>
          {convertNewlinesToBreaks(description)}
          {tooltip && (
            <ToolTipWrap>
              <Tooltip tooltipDesktopWidth={tooltipDesktopWidth} inverted={inverted}>
                {tooltip}
              </Tooltip>
            </ToolTipWrap>
          )}
        </span>
      </Description>
    </StatContainer>
  );
};

const TIGHT_MOBILE_BREAKPOINT = `@media only screen and (max-width: 27.5rem)`;

export const StatContainer = styled.div<{
  extended: boolean;
  customBreakpoint: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(p) => (p.extended ? "0" : "0 1.5rem")};

  ${(p) => `
    @media only screen and (max-width: ${p.extended ? "74rem" : p.customBreakpoint}) {
      padding-top: ${p.extended ? "0" : "2.5rem"};
    }
  `}

  ${TIGHT_MOBILE_BREAKPOINT} {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    max-width: 20rem;
    gap: 1.5rem;
    padding-top: 2.5rem;
  }
`;

export const Stat = styled.p<{ inverted: boolean }>`
  color: white;
  color: ${(p) => (p.inverted ? color.P100 : color.white)};
  font-size: 3.125rem;
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.div<{
  hasTooltip?: boolean;
  inverted: boolean;
  marginLeft?: string;
  marginRight?: string;
  weight?: boolean;
  maxWidth?: string;
  extended: boolean;
}>`
  color: white;
  color: ${(p) => (p.inverted ? color.B1100 : color.white)};
  font-size: 1rem;
  font-weight: ${(p) => (p.weight ? 400 : 700)};
  letter-spacing: 0.075rem;
  text-align: left;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "1.5rem")};
  margin-right: ${(p) => p.marginRight};
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : "9.375rem")};

  ${(p) => `
    @media only screen and (max-width: ${p.extended ? "74rem" : "65rem"}) {
      margin: 0 0 0 1.5rem;
    }
  `}

  ${TIGHT_MOBILE_BREAKPOINT} {
    margin: 0;
    text-align: center;
  }
`;

const ToolTipWrap = styled.span`
  position: relative;
`;
